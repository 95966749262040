import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './services/interceptor';
import { LoaderModule } from './components/loader/loader.module';
import { ToastNotificationModule } from './components/toast-notification/toast-notification.module';
import { RouterModule, Routes } from '@angular/router';
import { ADMIN, ACCOUNT } from './constants/routes';
import { AuthGuard } from './services/guards/auth-guard/auth.guard';
import { SelectivePreloadingStrategy } from './components/pre-load-strategy/selective-preload-strategy';
import {  DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import * as _moment from 'moment';


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY', // Adjust as needed
  },
  display: {
    dateInput: 'MM-DD-YYYY', // Format in the input field
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const appRoutes: Routes = [
  { path: '', redirectTo: ADMIN.path, pathMatch: 'full' },
  {
    path: ACCOUNT.path,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: ADMIN.path,
    data:{preload:true},
    loadChildren: () =>
      import('./modules/layout/layout.module').then((m) => m.LayoutModule),
      canActivate:[AuthGuard]

  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes,{preloadingStrategy:SelectivePreloadingStrategy}),
    BrowserAnimationsModule,
    HttpClientModule,
    LoaderModule,
    ToastNotificationModule
  ],
  providers: [httpInterceptorProviders,SelectivePreloadingStrategy,


    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }

  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
